var Utils = {};

Utils.convertAccents = function (str) {

    var search = [/ä|æ|ǽ/g, /ö|œ/g, /ü/g, /Ä/g, /Ü/g, /Ö/g, /À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ/g, /à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª/g, /Ç|Ć|Ĉ|Ċ|Č/g, /ç|ć|ĉ|ċ|č/g, /Ð|Ď|Đ/g, /ð|ď|đ/g, /È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě/g, /è|é|ê|ë|ē|ĕ|ė|ę|ě/g, /Ĝ|Ğ|Ġ|Ģ/g, /ĝ|ğ|ġ|ģ/g, /Ĥ|Ħ/g, /ĥ|ħ/g, /Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ/g, /ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı/g, /Ĵ/g, /ĵ/g, /Ķ/g, /ķ/g, /Ĺ|Ļ|Ľ|Ŀ|Ł/g, /ĺ|ļ|ľ|ŀ|ł/g, /Ñ|Ń|Ņ|Ň/g, /ñ|ń|ņ|ň|ŉ/g, /Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ/g, /ò|ó|ô|gõ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º/, /Ŕ|Ŗ|Ř/, /ŕ|ŗ|ř/, /Ś|Ŝ|Ş|Š/, /ś|ŝ|ş|š|ſ/, /Ţ|Ť|Ŧ/, /ţ|ť|ŧ/, /Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ/g, /ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ/g, /Ý|Ÿ|Ŷ/g, /ý|ÿ|ŷ/g, /Ŵ/g, /ŵ/g, /Ź|Ż|Ž/g, /ź|ż|ž/g, /Æ|Ǽ/g, /ß/g, /Ĳ/g, /ĳ/g, /Œ/g, /ƒ/g];
    var replace = ['ae', 'oe', 'ue', 'Ae', 'Ue', 'Oe', 'A', 'a', 'C', 'c', 'D', 'd', 'E', 'e', 'G', 'g', 'H', 'h', 'I', 'i', 'J', 'j', 'K', 'k', 'L', 'l', 'N', 'n', 'O', 'o', 'R', 'r', 'S', 's', 'T', 't', 'U', 'u', 'Y', 'y', 'W', 'w', 'Z', 'z', 'AE', 'ss', 'IJ', 'ij', 'OE', 'f'];

    var len = search.length;
    for (var i = 0; i < len; i++) {
        str = str.replace(search[i], replace[i]);
    }

    return str;

};

Utils.stem = function (txt) {
    return Utils.convertAccents(txt).toLowerCase();
}

Utils.search = function (source, match) {
    var throttle = -1;
    var matches = [];
    var len = match.length;
    var pos;

    for (var i = 0; i < len; i++) {
        if (!match[i].match(/\s+/g)) {
            pos = source.indexOf(match[i]);
            if (pos > -1) {
                source = source.substring(pos + 1);
                throttle += pos + 1;
                matches.push(throttle);
            } else {
                return [];
            }
        }
    }
    return matches;
};

Utils.intersec = function (a, b) {
    if (!a.length) {
        return [];
    }

    if (!b.length) {
        return [];
    }

    return a.filter((value) => b.includes(value));
};
